#summa:hover{
    background-color:black;
  
  }
  .sim:hover{
  
    border: 2px solid black;
  
  }
  
  #sim2:hover{
    background-color: black;
    color: white;
  }
  .sim4{
    background-color: yellow;
    border: none;
    font-size: 20px;
    width: 400px;
  }
  .sim5{
    padding: 120px;
  }
  #sim6{
    font-size: 30px;
  }
  .sim8{
    background-color: lightpink;
    
  }
  .sim9{
    border: 2px solid black;
   
    
  }
  #sim10:hover{
  
    background-color: black;
    color: white;
  
  }
  .sim11{
    background-color: yellow;
    width: 50px;
    
  }
  .sim12{
    opacity: 0.6;
  }
  .sim13{
    background-color: yellow;
    width: 110px;
  }
  .sim15{
    background-color: yellow;
    width: 180px;
  }
  .sim21{
    font-size: 20px;
    font-weight: bold;
   
  }
  .sim25{
    background: linear-gradient(skyblue,#fff,#ff0);
    font-family: Segoe UI,Tahoma,Geneva,Verdana,sans-serif;
    font-size: 20px;
    margin-top: 90px;
  }
  
  
  .sim27{
    font-size: 20px;
  
  }
  .sim27:hover{
    font-size: 20px;
    background-color: #ff0;
    
  }
  .sim30{
    background-color: #010110;
      color: #fff;
  }