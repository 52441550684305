.bg1{
    /* border: 1px solid black; */
}
.sancolor{
    font-size: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-style: italic;
}
.sanimg:hover{
    border: 1px solid black;
}