
.bg{
    /* border: 1px solid black; */
}
.san5{
    font-size: 30px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-style: italic;
}
.button .btn1{
    color: black;
    /* border: 1px solid black; */
}
.button .btn1:hover{
    background:linear-gradient(45deg,#2E3192,white , #1BFFFF);
    color: black;
}

.button .btn2{
    color: black;
    /* border: 1px solid black; */
}
.button .btn2:hover{
    background:linear-gradient(45deg,#2E3192,white , #1BFFFF);
    color: black;
}

.button .btn3{
    color: black;
    /* border: 1px solid black; */
}
.button .btn3:hover{
    background:linear-gradient(45deg,#2E3192,white , #1BFFFF);
    color: black;
}

.para1 p{
     font-size: 20px;
     font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.bgli{
    background:linear-gradient(45deg,#2E3192,white , #1BFFFF);
}













/* .Specification{
 display: grid;
 grid-template-columns: 250px 250px 250px;
 margin-top: 50px;
 font-size: 20px;
 margin-left: 60px;
}
.Specification div{
    border: 2px solid rgb(235, 230, 230);
   margin-left: 20px;
   padding: 20px;
   background-color: rgb(7, 117, 147);
   color: white;
   font-weight: 200;
}
#c1{
    background-color: green;
}
.Specification div:hover{
    opacity: 0.5;
}
#s1{
    padding: 30px;
    width: 750px;
    font-size: 18px;
    background:linear-gradient(green,white,rgb(7, 117, 147));
    margin-left: 700px;
    margin-top: 50px;
}
#coil{
    height: 300px;
    width: 500px;
    margin-top: -900px;
    
}
.footer5 {
    display: grid;
    grid-template-columns: 300px 300px 300px 300px;
    font-size: 16px;
    background-color: black;
    color: white;
    justify-content: center;
    padding: 100px;
    margin-top: -5px;
    

}
.footer5 h3{
    border-bottom: 6px solid yellow ;
    width: 100px;
    font-size: 20px;
} */