#icon1{
    font-size: 30px;
}

 h1#contact {
    position: relative;
    display: inline-block;
  }

  h1#contact::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: yellow; /* You can adjust the color to your preference */
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease;
  }

  h1#contact:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  .icon2{
    text-decoration: none;
    color: black;
  }

