.san{
    font-size: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    /* background:linear-gradient(90deg,skyblue,white,yellow); */
    background:linear-gradient(45deg,#2E3192,white , #1BFFFF);
   
}
.bg1{
    /* border: 1px solid black; */
}
.li1{
    list-style: disc;
}

.san2{
    font-size: 25px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.bg2{
    background-color: rgb(1, 1, 16);
}
.icon{
    font-size: 60px;
    background-color: rgb(1, 1, 16);
    color: white;
}
.san3 p{
color: white;
font-size: 25px;
}