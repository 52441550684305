
.bg1{
    /* border: 1px solid black; */
}



.san4 h2{
    font-size: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.img:hover{
    border: 2px solid black;
}













/* #head3{
    display: grid;
    grid-template-columns: 400px 400px 400px;
    justify-content: center;
}
#head3 div img{
    height: 400px;
    width: 400px;
    padding: 50px;
}
#head3 div h2{
    margin-left: 35px;
}
.footer6 {
    display: grid;
    grid-template-columns: 300px 300px 300px 300px;
    font-size: 16px;
    background-color: black;
    color: white;
    justify-content: center;
    padding: 100px;
    
    

}
.footer6 h3{
    border-bottom: 6px solid yellow ;
    width: 100px;
    font-size: 20px;
}
#head3 div img:hover{
    background-color:black;
} */

